import styled from 'styled-components';
import { Link as DefaultLink } from 'gatsby';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, DESKTOP_GRID_SIZE } from 'consts';
import { BackgroundImage } from 'components/ui';

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    position: relative;
`;

export const Image = styled(BackgroundImage)`
    .gatsby-image-wrapper {
        max-height: 100%;
    }

    div {
        height: 100%;
    }
`;

export const Details = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 5 + DESKTOP_GRID_SPACING * 4}rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 6rem;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE - DESKTOP_GRID_COLUMN * 2 - DESKTOP_GRID_SPACING * 2}rem;
        padding: 14.2rem 0;
    }

    @media ${MediaQueries.tablet} {
        flex-direction: column;
        padding: 12rem 7.4rem;
        align-items: center;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
        padding: 6rem 2rem;
        align-items: center;
    }
`;

export const Title = styled.h2`
    color: ${Colors.white};

    @media ${MediaQueries.tablet} {
        text-align: center;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
    }
`;

export const Description = styled.p`
    color: ${Colors.white};
    margin-top: 4rem;

    @media ${MediaQueries.tablet} {
        text-align: center;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
        margin-top: 2rem;
    }
`;

export const Link = styled(DefaultLink)`
    color: ${Colors.white};
    text-decoration: underline;
    cursor: pointer;
    font-weight: 400;

    @media ${MediaQueries.desktop} {
        margin-top: 4rem;
        margin-bottom: 14.3rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 4rem;
        text-align: center;
    }

    @media ${MediaQueries.phone} {
        margin-top: 2rem;
        text-align: center;
    }
`;

export const Card = styled.div`
    background-color: ${Colors.white};
    border-radius: 2.5rem;
    position: relative;

    @media ${MediaQueries.desktop} {
        height: 39.5rem;
        width: 38rem;
        padding: 4rem;
    }

    @media ${MediaQueries.tablet} {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem 6rem;
        width: 100%;
        height: 34.2rem;
    }

    @media ${MediaQueries.phone} {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4rem 4.8rem;
        width: 100%;
        height: 35.2rem;
    }
`;

export const Job = styled.h3`
    color: ${Colors.violet};
    margin: 4rem 0;

    @media ${MediaQueries.tablet} {
        text-align: center;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 4rem;
    }
`;

export const Priority = styled.p`
    background-color: ${Colors.magenta};
    color: ${Colors.white};
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10rem;
    min-height: 3.2rem;
    border-radius: 1.5rem;
`;
