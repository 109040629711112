import React from 'react';

import { SecondaryButton, RichText } from 'components/ui';
import { ourWork } from 'data/landing.json';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery, useQueryContext } from 'hooks';

import {
    ButtonContainer,
    Container,
    Description,
    Image,
    Info,
    Subtitle,
    Title
} from './OurWork.style';

export function OurWork() {
    const isDesktop = useMediaQuery(MediaQueries.desktop);
    const {
        images: {
            landing: {
                ourWork: { image }
            }
        }
    } = useQueryContext();

    return (
        <Container>
            <Image
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                position="relative"
                alt={ourWork.alt}
            />
            <Info>
                {isDesktop && <Title>{ourWork.title}</Title>}
                <Subtitle>{ourWork.subtitle}</Subtitle>
                <RichText>
                    <Description>{ourWork.description}</Description>
                </RichText>
                <ButtonContainer>
                    <SecondaryButton
                        name={analytics.landingOurWorkViewMoreProjects}
                        to="/portfolio/"
                    >
                        {ourWork.button}
                    </SecondaryButton>
                </ButtonContainer>
            </Info>
        </Container>
    );
}
