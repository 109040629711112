import React from 'react';
import { PageProps } from 'gatsby';

import { SEO } from 'components/ui';
import seo from 'data/seo.json';

import {
    LandingHero,
    WhatWeDo,
    OurWork,
    OurTechnologyStack,
    Testimonials,
    Industries,
    JobProposal,
    StartProject
} from 'components/landing';

export default function Landing(_: PageProps) {
    return (
        <>
            <SEO
                title={seo.landingTitle}
                description={seo.landingDescription}
                ogTitle={seo.landingOgTitle}
                ogDescription={seo.landingOgDescription}
                ogImage={seo.landingOgImage}
            />
            <LandingHero />
            <WhatWeDo />
            <OurWork />
            <OurTechnologyStack />
            <Industries />
            <Testimonials />
            <JobProposal />
            <StartProject />
        </>
    );
}
