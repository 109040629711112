import React from 'react';

import { RichText, Slider } from 'components/ui';
import { testimonials } from 'data/landing.json';
import { useQueryContext } from 'hooks';

import {
    Author,
    Container,
    Content,
    Description,
    Image,
    Position,
    Title
} from './Testimonials.style';

export function Testimonials() {
    const {
        images: {
            landing: {
                testimonials: { image }
            }
        }
    } = useQueryContext();

    return (
        <Container>
            <Image
                position="relative"
                scaleX={1.05}
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={testimonials.alt}
            />
            <Content>
                <Title>{testimonials.title}</Title>
                <Slider>
                    {testimonials.recommendations.map((recommendation, index) => (
                        <Slider.Item key={`slider-item-${index}`}>
                            <RichText>
                                <Description>{`*${recommendation.description}*`}</Description>
                            </RichText>
                            <Author>{recommendation.author}</Author>
                            <Position>{recommendation.position}</Position>
                        </Slider.Item>
                    ))}
                </Slider>
            </Content>
        </Container>
    );
}
