import React from 'react';

import { PrimaryButton, RichText } from 'components/ui';
import { whatWeDo } from 'data/landing.json';
import analytics from 'data/analytics.json';
import { buttons } from 'data/general.json';

import {
    Container,
    Content,
    Description,
    Title,
    FeaturesContainer,
    Feature,
    Label,
    ButtonContainer
} from './WhatWeDo.style';

export function WhatWeDo() {
    return (
        <Container>
            <Content>
                <Title>{whatWeDo.title}</Title>
                <RichText>
                    <Description>{whatWeDo.description}</Description>
                </RichText>
                <FeaturesContainer>
                    {whatWeDo.features.map((item, index) => {
                        const Icon = require(`../../../../static/images/services/${item.icon}`);
                        return (
                            <Feature key={`feature-container-${index}`}>
                                <Icon />
                                <Label>{item.label}</Label>
                            </Feature>
                        );
                    })}
                </FeaturesContainer>
                <ButtonContainer>
                    <PrimaryButton name={analytics.landingWhatWeDoLearnMore} to="/contact/">
                        {buttons.contactCTA}
                    </PrimaryButton>
                </ButtonContainer>
            </Content>
        </Container>
    );
}
