import React from 'react';

import { TertiaryButton } from 'components/ui';
import { jobProposal } from 'data/landing.json';
import analytics from 'data/analytics.json';
import { useQueryContext } from 'hooks';

import {
    Card,
    Container,
    Content,
    Description,
    Details,
    Image,
    Job,
    Link,
    Title,
    Priority
} from './JobProposal.style';

export function JobProposal() {
    const {
        images: {
            landing: {
                jobProposal: { image }
            }
        }
    } = useQueryContext();

    return (
        <Container>
            <Image
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={jobProposal.alt}
            >
                <Content>
                    <Details>
                        <Title>{jobProposal.title}</Title>
                        <Description>{jobProposal.description}</Description>
                        <Link to="/careers#jobs">{jobProposal.linkTitle}</Link>
                    </Details>
                    <Card>
                        <Priority>{jobProposal.cardJobPriority}</Priority>
                        <Job>{jobProposal.cardJobName}</Job>
                        <TertiaryButton
                            name={analytics.landingJobProposalApplyNow}
                            to={`/careers/${jobProposal.cardJobLink}`}
                        >
                            {jobProposal.cardButtonLabel}
                        </TertiaryButton>
                    </Card>
                </Content>
            </Image>
        </Container>
    );
}
