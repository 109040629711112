import styled from 'styled-components';
import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING } from 'consts';
import { BackgroundImage } from 'components/ui';

export const Image = styled(BackgroundImage)`
    .gatsby-image-wrapper {
        @media ${MediaQueries.desktop} {
            min-height: 97.5rem;
        }

        @media ${MediaQueries.tablet} {
            min-height: 113rem;
        }

        @media ${MediaQueries.phone} {
            min-height: 93rem;
        }
    }
`;

export const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    width: 100%;
    z-index: 2;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 8 + DESKTOP_GRID_SPACING * 7}rem;
        top: 40%;
        bottom: 6rem;
    }

    @media ${MediaQueries.tablet} {
        padding: 44rem 7.4rem 12rem 7.4rem;
    }

    @media ${MediaQueries.phone} {
        padding: 30rem 2rem 4rem 2rem;
    }
`;

export const Title = styled.h3`
    color: ${Colors.violet};
    text-align: center;
    margin-bottom: 8rem;

    @media ${MediaQueries.phone} {
        margin-bottom: 4rem;
    }
`;

export const Description = styled.p`
    color: ${Colors.violet};
    margin-bottom: 4rem;

    @media ${MediaQueries.desktop} {
        font-size: 2.4rem;
        line-height: 3.8rem;
    }

    @media ${MediaQueries.tablet} {
        line-height: 3.8rem;
        font-size: 2.4rem;
    }
`;

export const Author = styled.p`
    color: ${Colors.violet};
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1rem;
    width: 100%;
`;

export const Position = styled.span`
    display: block;
    color: ${Colors.violet};
    width: 100%;
`;
