import React from 'react';

import { startProject } from 'data/landing.json';
import analytics from 'data/analytics.json';
import { buttons } from 'data/general.json';
import { PrimaryButton } from 'components/ui';

import { ButtonContainer, Container, Content, Title } from './StartProject.style';

export function StartProject() {
    return (
        <Container>
            <Content>
                <Title>{startProject.title}</Title>
                <ButtonContainer>
                    <PrimaryButton name={analytics.landingStartProjectGetInTouch} to="/contact/">
                        {buttons.contactCTA}
                    </PrimaryButton>
                </ButtonContainer>
            </Content>
        </Container>
    );
}
