import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';

export const Container = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media ${MediaQueries.phone} {
        flex-direction: column;
    }
`;

export const Content = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;
    z-index: 2;
    padding: 24rem 2rem 24rem 2rem;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE + 8}rem;
        padding: 24rem 0 20rem 0;
    }

    @media ${MediaQueries.phone} {
        padding: 12rem 2rem 8rem 2rem;
    }
`;

export const Title = styled.h3`
    color: ${Colors.violet};
    text-align: center;
    padding-bottom: 7rem;

    @media ${MediaQueries.phone} {
        padding-bottom: 3rem;
    }
`;

export const Icons = styled.div`
    display: flex;
    align-self: center;
    justify-content: center;
    flex-wrap: wrap;
`;

export const BreakRow = styled.div`
    flex-basis: 100%;
    width: 0;
`;

export const Icon = styled.img`
    transition: transform 100ms ease-in-out;
    object-fit: scale-down;

    :hover {
        transform: scale(1.3);
    }

    @media ${MediaQueries.desktop} {
        height: 5rem;
        margin: 2.4rem 3.2rem;
    }

    @media ${MediaQueries.tablet} {
        height: 4.4rem;
        margin: 2rem 3.2rem;
    }

    @media ${MediaQueries.phone} {
        height: 3.7rem;
        margin: 2rem 2rem;
    }
`;
