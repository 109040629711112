import React from 'react';

import landing from 'data/landing.json';
import { useQueryContext } from 'hooks';

import {
    Container,
    Title,
    Description,
    Details,
    LandingBackgroundImage
} from './LandingHero.style';

export function LandingHero() {
    const {
        images: {
            landing: { hero }
        }
    } = useQueryContext();

    return (
        <Container>
            <LandingBackgroundImage
                mobile={hero.mobile}
                tablet={hero.tablet}
                desktop={hero.desktop}
                alt={landing.hero.alt}
            >
                <Details>
                    <Title>{landing.title}</Title>
                    <Description>{landing.description}</Description>
                </Details>
            </LandingBackgroundImage>
        </Container>
    );
}
