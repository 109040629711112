import React from 'react';

import { industries } from 'data/landing.json';
import { useQueryContext } from 'hooks';
import { RichText, BackgroundImage } from 'components/ui';

import {
    Container,
    Title,
    Description,
    Content,
    FeaturesContainer,
    Feature,
    Label,
    TextContainer
} from './Industries.style';

export function Industries() {
    const {
        images: {
            landing: {
                industries: { image }
            }
        }
    } = useQueryContext();

    return (
        <Container>
            <BackgroundImage
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={industries.alt}
            />
            <Content>
                <TextContainer>
                    <Title>{industries.title}</Title>
                    <RichText>
                        <Description>{industries.description}</Description>
                    </RichText>
                </TextContainer>
                <FeaturesContainer>
                    {industries.features.map((item, index) => {
                        const Icon = require(`../../../../static/images/${item.icon}`);
                        return (
                            <Feature key={`feature-container-${index}`}>
                                <Icon />
                                <Label>{item.label}</Label>
                            </Feature>
                        );
                    })}
                </FeaturesContainer>
            </Content>
        </Container>
    );
}
