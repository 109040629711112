import styled from 'styled-components';

import { DESKTOP_GRID_COLUMN, DESKTOP_GRID_SPACING, TABLET_GRID_SIZE } from 'consts';
import { BackgroundImage } from 'components/ui';
import { MediaQueries, Colors, Sizes } from 'environment';

export const Container = styled.section`
    display: flex;
    position: relative;
`;

export const Image = styled(BackgroundImage)`
    @media ${MediaQueries.phone} {
        min-height: 102rem;
    }
`;

export const Info = styled.div`
    width: 100%;
    position: absolute;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
        left: 51%;
        top: 46%;
        transform: translateY(-50%);
    }

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}px) {
        max-width: 40%;
        top: 50%;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        left: 50%;
        transform: translateX(-50%);
        bottom: 12rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
        bottom: 6rem;
    }
`;

export const Title = styled.h2`
    color: ${Colors.white};

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}px) {
        font-size: 3.2rem;
        line-height: 4rem;
    }

    @media ${MediaQueries.tablet} {
        text-align: center;
    }
`;

export const Subtitle = styled.h4`
    color: ${Colors.white};

    @media ${MediaQueries.desktop} {
        margin-top: 4rem;
    }

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}px) {
        margin-top: 2rem;
        font-size: 2rem;
        line-height: 2.9rem;
    }

    @media ${MediaQueries.tablet} {
        text-align: center;
        font-size: 4.8rem;
    }

    @media ${MediaQueries.phone} {
        text-align: center;
        font-size: 3.6rem;
    }
`;

export const Description = styled.p`
    color: ${Colors.white};

    @media ${MediaQueries.desktop} {
        margin-top: 2rem;
    }

    @media ${MediaQueries.tablet} {
        margin-top: 4rem;
        text-align: center;
    }

    @media ${MediaQueries.phone} {
        margin-top: 4rem;
        text-align: center;
    }
`;

export const ButtonContainer = styled.div`
    @media ${MediaQueries.desktop} {
        margin-top: 8rem;
    }

    @media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}px) {
        margin-top: 4rem;
    }

    @media ${MediaQueries.tablet} {
        display: flex;
        justify-content: center;
        margin-top: 8rem;
    }

    @media ${MediaQueries.phone} {
        display: flex;
        justify-content: center;
        margin-top: 4rem;
    }
`;
