import styled from 'styled-components';

import { MediaQueries, Colors } from 'environment';
import {
    DESKTOP_GRID_SIZE,
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    TABLET_GRID_SIZE
} from 'consts';

export const Container = styled.section`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

export const Content = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    color: ${Colors.violet};
    z-index: 2;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE + 4}rem;
        padding-bottom: 6rem;
        justify-content: center;
        padding-left: 2rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
    }

    @media ${MediaQueries.phone} {
        margin: 0 2rem;
    }
`;

export const TextContainer = styled.div`
    max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
    padding-bottom: 9.7rem;

    @media ${MediaQueries.tablet} {
        text-align: center;
        padding-bottom: 6rem;
        max-width: 100%;
    }

    @media ${MediaQueries.phone} {
        padding-bottom: 2rem;
        text-align: center;
        justify-content: center;
    }
`;

export const Title = styled.h3`
    color: ${Colors.violet};
    padding-bottom: 4rem;

    @media ${MediaQueries.phone} {
        padding-bottom: 2rem;
    }
`;

export const Description = styled.p`
    color: ${Colors.violet};
`;

export const FeaturesContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media ${MediaQueries.desktop} {
        width: 100%;
    }

    @media ${MediaQueries.tablet} {
        justify-content: space-between;
    }

    @media ${MediaQueries.phone} {
        justify-content: center;
        width: 100%;
    }
`;

export const Feature = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 25.6rem;
    width: ${DESKTOP_GRID_COLUMN * 3 + DESKTOP_GRID_SPACING * 2}rem;
    margin: 0rem 2rem 4rem 0;
    background-color: ${Colors.white};
    border-radius: 2.5rem;
    box-shadow: 0 20px 40px -20px rgba(77, 52, 91, 0.3);
    transition: background-color 0.2s, color 0.2s;

    svg {
        margin: 4rem 0 2rem 4rem;
        path {
            transition: fill 0.2s;
        }
    }

    &:hover {
        color: ${Colors.white};

        svg,
        path {
            fill: ${Colors.white};
        }

        :nth-child(3n + 1) {
            background: ${Colors.magenta};
        }

        :nth-child(3n + 2) {
            background: ${Colors.orange};
        }

        :nth-child(3n + 3) {
            background: ${Colors.green};
        }
    }

    @media ${MediaQueries.tablet} {
        justify-content: space-between;
        margin: 2rem 0 2rem 0;
        justify-content: flex-start;
    }

    @media ${MediaQueries.phone} {
        margin: 2rem 2rem;
    }
`;

export const Icon = styled.div`
    margin: 4rem 0 2rem 4rem;
    width: 8rem;
    height: 8rem;
`;

export const Label = styled.h4`
    padding: 0 4rem;
    line-height: 1.58;
`;
