import React from 'react';

import { ourTechnologyStack } from 'data/landing.json';
import { useMediaQuery, useQueryContext } from 'hooks';
import { BackgroundImage } from 'components/ui';
import { MediaQueries } from 'environment';
import { BreakRow, Container, Content, Icon, Icons, Title } from './OurTechnologyStack.style';

const DESKTOP_ROWS = [5, 3, 4, 4, 3, 3];
const TABLET_ROWS = [5, 3, 4, 4, 3, 3];
const MOBILE_ROWS = [8, 8, 3, 3];

export function OurTechnologyStack() {
    const {
        images: {
            landing: {
                ourTechnology: { image }
            }
        }
    } = useQueryContext();

    const isDesktop = useMediaQuery(MediaQueries.desktop);
    const isTablet = useMediaQuery(MediaQueries.tablet);

    const rows = isDesktop ? DESKTOP_ROWS : isTablet ? TABLET_ROWS : MOBILE_ROWS;

    let currentRow = rows[0];
    let currentRowIndex = 0;

    return (
        <Container>
            <BackgroundImage
                mobile={image.mobile}
                tablet={image.tablet}
                desktop={image.desktop}
                alt={ourTechnologyStack.alt}
            />
            <Content>
                <Title>{ourTechnologyStack.title}</Title>
                <Icons>
                    {ourTechnologyStack.features.map((item, index) => {
                        const shouldBreakRow = index !== 0 && index % currentRow === 0;
                        if (shouldBreakRow) {
                            currentRowIndex++;
                            currentRow += rows[currentRowIndex];
                        }
                        return (
                            <React.Fragment key={`icon-${index}`}>
                                {shouldBreakRow && <BreakRow />}
                                <Icon
                                    src={`/images/our-tech-stack/${item.icon.src}`}
                                    alt={item.icon.alt}
                                />
                            </React.Fragment>
                        );
                    })}
                </Icons>
            </Content>
        </Container>
    );
}
