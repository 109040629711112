import styled from 'styled-components';
import { MediaQueries, Colors } from 'environment';
import {
    DESKTOP_GRID_SIZE,
    DESKTOP_GRID_COLUMN,
    DESKTOP_GRID_SPACING,
    TABLET_GRID_SIZE
} from 'consts';

export const Container = styled.section`
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    position: relative;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    @media ${MediaQueries.desktop} {
        max-width: ${DESKTOP_GRID_SIZE}rem;
        padding: 0 ${DESKTOP_GRID_COLUMN * 2 + DESKTOP_GRID_SPACING * 2}rem;
        padding-top: 23rem;
        padding-bottom: 12rem;
    }

    @media ${MediaQueries.tablet} {
        max-width: ${TABLET_GRID_SIZE}rem;
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.phone} {
        padding: 0 2rem;
        padding-bottom: 6rem;
    }
`;

export const Title = styled.h3`
    color: ${Colors.violet};
    text-align: center;
`;

export const Description = styled.p`
    color: ${Colors.violet};
    text-align: center;
    margin-top: 4rem;

    @media ${MediaQueries.phone} {
        margin-top: 2rem;
    }
`;

export const FeaturesContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;

    @media ${MediaQueries.desktop} {
        margin-top: 8rem;
        margin-bottom: 12rem;
    }

    @media ${MediaQueries.tablet} {
        flex-direction: column;
        flex-wrap: wrap;
        margin-top: 4rem;
        margin-bottom: 4rem;
    }

    @media ${MediaQueries.phone} {
        flex-direction: column;
        margin-top: 4rem;
    }
`;

export const Feature = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media ${MediaQueries.desktop} {
        margin: 0 5.8rem;
    }

    @media ${MediaQueries.tablet} {
        margin-bottom: 4rem;
        width: 100%;
    }

    @media ${MediaQueries.phone} {
        margin-bottom: 8rem;
    }
`;

export const Label = styled.h4`
    color: ${Colors.violet};
    padding-top: 4rem;

    @media ${MediaQueries.phone} {
        padding-top: 2rem;
    }
`;
export const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;

    @media ${MediaQueries.phone} {
        width: 100%;
    }
`;
